import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';

import PageTop from '../../components/PageTop';
import ClientLevel from '../../components/ClientLevel';
import ClientEdition from '../../parts/ClientEdition';
import Spinner from '../../components/Spinner';

import api from '../../services/api';

import Noty from 'noty'

import 'noty/lib/noty.css';
import 'noty/lib/themes/mint.css';
import './show.css';

import ClientReport from '../../parts/ClientReports';

function ClientShow() {
    let history = useHistory();
    let location = useLocation();
    const { id: clientId } = useParams()


    const [clientName, setClientName] = useState(location.state ? location.state.clientName : '');
    const [clientScore, setClientScore] = useState(location.state ? location.state.score : '');
    const [clientActive, setClientActive] = useState(location.state ? location.state.active : '');
    const [responsibleName, setResponsibleName] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [sendingDay, setSendingDay] = useState(0);
    const [testClient, setTestClient] = useState(0);
    const [loading, setLoading] = useState(false);

    const [services, setServices] = useState([])
    const [activeServices, setActiveServices] = useState([])
    const [activeTab, setActiveTab] = useState(0);
    const [navigationList, setNavigationList] = useState([
        {
            name: 'Visão geral'
        },
        {
            name: 'Informações'
        }
    ]);

    useEffect(() => {
        setLoading(true);
        async function loadData() {
            await api.get(`client/${clientId}`)
                .then(response => {
                    // CLIENT INFOS
                    setClientName(response.data.client.name+(response.data.client.active ? "":" - (Inativo)"))
                    setResponsibleName(response.data.client.responsible_name)
                    setClientEmail(response.data.client.email)
                    setSendingDay(response.data.client.sending_day)
                    setTestClient(response.data.client.test_client)
                    setClientActive(response.data.client.active)

                    // PACKET OF SERVICES
                    let loadedServices = [...response.data.client.services];
                    loadedServices.map(loadedService => {
                        loadedService.questions = loadedService.questions.filter(question => {
                            question.value = question.id;
                            question.label = question.content;

                            return question;
                        })

                        loadedService.selected = loadedService.selected.filter(question => {
                            question.value = question.id;
                            question.label = question.content;

                            return question;
                        })
                    })

                    setActiveServices(loadedServices);
                    setServices([...response.data.unassociatedServices]);
                    setLoading(false);
                })
        }

        loadData();
    }, [])

    async function addService(serviceId, serviceName) {
        setLoading(true);
        await api.get(`question/all/${serviceId}`)
            .then(response => {
                setActiveServices([...activeServices, {
                    id: serviceId,
                    name: serviceName,
                    selected: [],
                    questions: (response.data.data).filter(question => {
                        question.value = question.id;
                        question.label = question.content;
                        return question;
                    }),
                }])
                setServices(services.filter(service => service.id !== serviceId))
                setLoading(false);
            })
    }

    function selectActions(data, action) {
        if (action.removedValue) {
            setActiveServices(activeServices.filter(activeService => {
                if (activeService.id === action.removedValue.service_id) {
                    activeService.selected = activeService.selected.filter(questionSelected => questionSelected.id !== action.removedValue.id)
                }
                return activeService;
            }))
        } else if (action.action === 'select-option') {
            setActiveServices(activeServices.filter(activeService => {
                if (activeService.id === action.option.service_id) {
                    activeService.selected = [...activeService.selected, action.option];
                }
                return activeService;
            }))
        }
    }

    async function saveChanges(event) {
        setLoading(true);
        await api.put(`client/update/${clientId}`, {
            name: clientName,
            responsible_name: responsibleName,
            email: clientEmail,
            sending_day: sendingDay,
            activeServices
        }).then(response => {
            setLoading(false);
            window.showNotification('success', 'Alterações salvas com sucesso!')
        }).catch(response => {
            setLoading(false);
            window.showNotification('error', 'Houve um erro ao realizar a alteração')
        })
    }

    async function sendEvaluateEmail() {
        setLoading(true);
        await api.post(`${clientId}/evaluate-my-business`)
            .then(response => {
                setLoading(false);
                window.showNotification('success', 'Email enviado com sucesso!');
            }).catch(response => {
                setLoading(false);
                window.showNotification('error', 'Ocorreu um erro ao enviar o email!');
            })
    }

    async function sendNps(serviceId) {
        setLoading(true);
        await api.post('nps/store', {
            service_id: serviceId,
            clients: [clientId]
        }).then(response => {
            setLoading(false);
            new Noty({
                theme: 'mint',
                timeout: 10000,
                progressBar: true,
                closeWith: ['click', 'button'],
                type: 'success',
                layout: 'topRight',
                text: 'NPS enviado com sucesso!'
            }).show();
        }).catch(response => {
            setLoading(false);
            new Noty({
                theme: 'mint',
                timeout: 10000,
                progressBar: true,
                closeWith: ['click', 'button'],
                type: 'error',
                layout: 'topRight',
                text: 'Ocorreu um erro ao enviar o nps!'
            }).show();
        })
    }

    const buttons = [
        {
            text: 'Avaliação no Google',
            style: 'blue',
            icon: 'business_center',
            action: sendEvaluateEmail
        },
        {
            text: 'Salvar',
            style: 'green',
            action: saveChanges
        },
        {
            text: clientActive ? 'Desativar cliente' : 'Apagar cliente',
            style: 'black',
            action: clientActive ? deactivateClient : deleteClient,
        },
    ]

    async function deactivateClient() {
        setLoading(true);
        await api.post(`client/deactivate/${clientId}`)
            .then(response => {
                setLoading(false);
                window.showNotification('success', 'Cliente desativado com sucesso!');
                history.replace({
                    pathname: `/clientes`,
                    state: {}
                })
            })
    }

    async function deleteClient() {
        setLoading(true);
        await api.delete(`client/delete/${clientId}`)
            .then(response => {
                setLoading(false);
                window.showNotification('success', 'Cliente apagado com sucesso!');
                history.replace({
                    pathname: `/clientes`,
                    state: {}
                })
            })
    }

    return (
        <>
            <PageTop title={clientName} navigation={true}
                navigationItens={navigationList}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                comeBackUrl="/clientes"
                comeBack={true}
                buttons={buttons}
                additionalInfoElement={<ClientLevel score={clientScore} />}
            />
            <div className="content row pb-5 px-20">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tab-content">
                                <div className={`tab-pane fade ${activeTab === 0 ? 'show active' : ''}`}>
                                    <ClientReport score={clientScore} clientId={clientId} />
                                </div>

                                <div className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`}>
                                    <ClientEdition services={services} activeServices={activeServices}
                                        addService={addService}
                                        clientName={clientName}
                                        clientEmail={clientEmail}
                                        clientActive={clientActive}
                                        responsibleName={responsibleName}
                                        sendingDay={sendingDay}
                                        setClientName={setClientName}
                                        setClientEmail={setClientEmail}
                                        setResponsibleName={setResponsibleName}
                                        setSendingDay={setSendingDay}
                                        selectActions={selectActions}
                                        sendNps={sendNps}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Spinner />}
        </>
    );
}

export default ClientShow;