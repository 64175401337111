import React from 'react';
import CircleProgress from '../../components/CircleProgress';

function Overview({ overview, score }) {
    return (
        <>
            <div className="row">
                <div className="col-md-4">
                    <CircleProgress score={score} />
                </div>
                <div className="col-md-8">
                    <small>Última observação</small>
                    <div className="mt-2">
                        <p className="fs-14">{overview.latest_observation}</p>
                    </div>
                </div>
            </div>
            <div className="row additional-report-data mt-3">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6 align-items-center d-flex">
                            <div className="icon-block">
                                <span className="material-icons">thumb_up</span>
                            </div>
                            <div className="ml-2 d-flex flex-column justify-content-center">
                                <p className="mb-0">{overview.likes_quantity}</p>
                                <small className="small-additional-report">Likes</small>
                            </div>
                        </div>
                        <div className="col-md-6 align-items-center d-flex">
                            <div className="icon-block">
                                <span className="material-icons">thumb_down</span>
                            </div>
                            <div className="ml-2 d-flex flex-column justify-content-center">
                                <p className="mb-0">{overview.dislikes_quantity}</p>
                                <small className="small-additional-report">Dislikes</small>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-6 align-items-center d-flex">
                            <div className="icon-block">
                                <span className="material-icons">email</span>
                            </div>
                            <div className="ml-2 d-flex flex-column justify-content-center">
                                <p className="mb-0">{overview.sended_quantity}</p>
                                <small className="small-additional-report">Pesquisas disparadas</small>
                            </div>
                        </div>
                        <div className="col-md-6 align-items-center d-flex">
                            <div className="icon-block">
                                <span className="material-icons">question_answer</span>
                            </div>
                            <div className="ml-2 d-flex flex-column justify-content-center">
                                <p className="mb-0">{overview.answered_researches}</p>
                                <small className="small-additional-report">Pesquisas respondidas</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Overview;