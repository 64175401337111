import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import api from '../../services/api'
import { login, isAuthenticated } from '../../services/auth'

import Spinner from '../../components/Spinner';

import Noty from 'noty'

import './style.css';
import 'noty/lib/noty.css';
import 'noty/lib/themes/mint.css';

export default function Login() {


    let history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    if (isAuthenticated()) {
        history.push('/');
    }

    async function handleSubmit(e) {
        setLoading(true);
        e.preventDefault();
        await api.post('login', {
            email,
            password
        }).then(response => {
            setLoading(false);
            login(response.data.token);
            history.push('/');
        }).catch(error => {
            setLoading(false);
            new Noty({
                theme: 'mint',
                timeout: 10000,
                progressBar: true,
                closeWith: ['click', 'button'],
                type: 'error',
                layout: 'topRight',
                text: error.response.data.message
            }).show();
        });
    }
    return (
        <>
            <div id="login">
                <div className="login-content">
                    <div className="logo">
                        <img src="/logo-full-h55.png" alt="Verticis" width="150" />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="input-block">
                            <input type="email"
                                name="email"
                                id="user_email"
                                placeholder="Digite seu e-mail"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="input-block">
                            <input type="password"
                                name="password"
                                id="user_password"
                                placeholder="Sua senha"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="form-submit">
                            <button type="submit">Entrar</button>
                        </div>
                    </form>
                </div>
                <div className="login-background" />
            </div>
            {loading && <Spinner />}
        </>
    );
}
