import React, { useState, useEffect, useContext } from 'react';

import api from '../../services/api'


import Noty from 'noty';

import 'noty/lib/noty.css';
import 'noty/lib/themes/mint.css';
import Sidenav from '../../components/Sidenav';
import Spinner from '../../components/Spinner';

function ServiceSimpleForm({ action }) {
    const [questions, setQuestions] = useState([]);
    const [removedQuestions, setRemovedQuestions] = useState([]);
    const [serviceName, setServiceName] = useState('');
    const [actionSidenav, setActionSidenav] = useContext(action);
    const [loading, setLoading] = useState(false);

    function addQuestion() {
        setQuestions([...questions, { content: '' }]);
    }

    function removeQuestion(id, key) {
        if (id) {
            setRemovedQuestions([...removedQuestions, { id }])
        }
        setQuestions(questions.filter((value, index) => key !== index || value.id !== id));
    }

    async function handleSubmit(e) {
        setLoading(true);
        e.preventDefault();
        await api.post(`service/store`, {
            name: serviceName,
            questions
        }).then(response => {
            setLoading(false);
            new Noty({
                theme: 'mint',
                timeout: 10000,
                progressBar: true,
                closeWith: ['click', 'button'],
                type: 'success',
                layout: 'topRight',
                text: 'Serviço cadastrado com sucesso!'
            }).show();
        })
        setActionSidenav(false);
    }

    return (
        <>
            <Sidenav title="Adicionar serviço" action={action} width="50%">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-block d-flex flex-column">

                                <label htmlFor="service_name">Nome</label>
                                <input type="text"
                                    name="name"
                                    id="service_name"
                                    placeholder="Nome"
                                    value={serviceName}
                                    required
                                    onChange={(e) => setServiceName(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="sidenav-divisor mt-3">
                        <span>Questões</span>
                    </div>
                    <div className="questions-block mt-4">
                        {

                            questions.map((question, key) => (
                                <div className="row">
                                    <div className="col-md-12 input-block d-flex flex-row">
                                        <input
                                            type="text"
                                            className="flex-1"
                                            placeholder="Pergunta"
                                            value={question.content}
                                            onChange={(e) => setQuestions(questions.filter((q, index) => {
                                                if (key === index) {
                                                    q.content = e.target.value
                                                }
                                                return q;
                                            }))}
                                        />
                                        <button className="custom-btn d-flex align-items-center justify-content-center ml-2" type="button" onClick={() =>
                                            removeQuestion(question.id, key)
                                        }>
                                            <span className="material-icons delete-data">delete</span>
                                        </button>
                                    </div>
                                </div>
                            ))
                        }
                        {

                            <button className="add-item-repeater" type="button" onClick={() => addQuestion()}>
                                ADD <i className="material-icons">add</i>
                            </button>
                        }
                    </div>
                    <div className="submit-form">
                        <button type="submit">Finalizar</button>
                    </div>
                </form>
            </Sidenav>
            {loading && <Spinner />}
        </>
    );
}

export default ServiceSimpleForm
