import React from 'react';
import {useHistory} from 'react-router-dom';

import PageTop from '../../components/PageTop';
import { logout } from '../../services/auth';

export default function Profile() {

    const history = useHistory();

    const buttons = [
        {
            text: 'Sair',
            style: 'black',
            action: handleLogout
        },
    ]

    function handleLogout() {
        logout();
        history.push('/');
    }

    return(
        <>
            <PageTop title={'Perfil'} navigation={false} buttons={buttons}/>
        </>
    );
}