import React, { useEffect, useState } from 'react';

import DashboardItem from '../../components/DashboardItem';
import api from '../../services/api';
import PageTop from '../../components/PageTop';
import CustomDataTable from '../../components/CustomDataTable';
import Spinner from '../../components/Spinner';

export default function Dashboard() {
    const [report, setReport] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function loadReport() {
            setLoading(true);
            api.get('dashboard/report')
                .then(response => {
                    setReport(response.data);
                    setLoading(false);
                })
        }
        loadReport();
    }, [])

    function returnScoreIconName(score) {
        return score >= 75 ? 'sentiment_very_satisfied' : (score >= 50 && score <= 74) ? 'sentiment_satisfied' : (score >= 0 && score <= 49) ? 'sentiment_dissatisfied' : 'sentiment_very_dissatisfied';
    }

    const dataTableColumns = [
        {
            name: 'Cliente',
            selector: 'client_name',
            sortable: true
        },
        {
            name: 'Score',
            selector: 'score',
            sortable: true
        }
    ];

    return (
        <>
            <PageTop title={'Dashboard'} navigation={false} />
            {
                report
                    ?
                    <>
                        <div className="row">
                            <DashboardItem title="NPS" icon={returnScoreIconName(report.report.score)} value={Math.round(report.report.score)} />
                            <DashboardItem title="Opiniões" icon="comment" value={report.report.observation_quantity} />
                            <DashboardItem title="Disparos" icon="email" value={report.report.quantity_sended} />
                        </div>
                        <div className="row mt-4">
                            <DashboardItem title="Detratores">
                                <CustomDataTable paginationPerPage={5} noRowsPerPage={true} data={report.detractorsReport} columns={dataTableColumns} />
                            </DashboardItem>
                            <DashboardItem title="Neutros">
                                <CustomDataTable paginationPerPage={5} noRowsPerPage={true} data={report.neutralReport} columns={dataTableColumns} />
                            </DashboardItem>
                            <DashboardItem title="Promotores">
                                <CustomDataTable paginationPerPage={5} noRowsPerPage={true} data={report.promotersReport} columns={dataTableColumns} />
                            </DashboardItem>
                        </div>
                    </>
                    :
                    null
            }
            {loading && <Spinner />}
        </>
    );
}
