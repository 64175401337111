import React, { useEffect } from 'react';

function Head({ title, children }) {
  useEffect(() => {
      document.title = `NPS - ${title ?? ''}`
  }, [title])

  return children;
}

export default Head;