import React, { memo, useEffect, useState } from 'react';
import Chart from 'chart.js';

import Overview from '../../parts/ClientReports/Overview';
import QuestionsReport from '../../parts/ClientReports/QuestionsReport';
import ResearchesReport from '../../parts/ClientReports/ResearchesReport';
import ReportCard from '../../components/ReportCard';
import Spinner from '../../components/Spinner';

import api from '../../services/api'

function ClientReport({ score, clientId }) {
    const [overview, setOverview] = useState({})
    const [questionsAnswersReport, setQuestionsAnswersReport] = useState([])
    const [chartReport, setChartReport] = useState([])
    const [npsList, setNpsList] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function loadReport() {
            setLoading(true);
            await api.get(`nps/client/${clientId}/report`).then(response => {
                setOverview(response.data.report.overview);
                setQuestionsAnswersReport(Object.keys(response.data.report['questionsAnswersReport']).map(key => {
                    return { service_name: key, questions: response.data.report['questionsAnswersReport'][key] };
                }));

                setChartReport(Object.keys(response.data.report['clientChartReport']).map(key => {
                    return { service_name: key, report: response.data.report['clientChartReport'][key] };
                }));

                setNpsList(Object.keys(response.data.report['npsList']).map(key => {
                    return { service_name: key, list: response.data.report['npsList'][key] };
                }));
                setLoading(false);
            })
        }
        loadReport();
    }, [])

    useEffect(() => {
        if (chartReport.length) {
            let reportChartEl = document.getElementById('report-chart');
            new Chart(reportChartEl, {
                type: 'bar',
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                userCallback: function (label, index, labels) {
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }

                                },
                            }
                        }]
                    }
                },
                data: {
                    labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                    datasets: chartReport.map(item => {
                        return {
                            label: item.service_name,
                            data: item['report'].map(reportItem => { return { x: reportItem.month_name, y: reportItem.score } }),
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: generateRandomRGBA(),
                            borderWidth: 1
                        }
                    })
                }
            });
        }
    }, [chartReport])

    function generateRandomRGBA() {
        var o = Math.round, r = Math.random, s = 255;
        return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + r().toFixed(1) + ')';
    }

    return (
        <>

            <div className="row">
                <div className="col-md-6">
                    <ReportCard title="Overview" customClass="mxh-342">
                        <Overview overview={overview} score={score} />
                    </ReportCard>
                </div>
                <div className="col-md-6">
                    <ReportCard customClass="mxh-342">
                        {
                            questionsAnswersReport.length
                                ?
                                <QuestionsReport questionsAnswersReport={questionsAnswersReport} />
                                :
                                <p>Nenhuma resposta encontrada</p>
                        }
                    </ReportCard>
                </div>
            </div>
            {
                chartReport.length
                    ?
                    <div className="row mt-4">
                        <div className="col-md-12">
                            <ReportCard>
                                <canvas id="report-chart" height="400"></canvas>
                            </ReportCard>
                        </div>
                    </div>
                    :
                    null
            }
            <div className="row mt-4">
                <div className="col-md-12">
                    {
                        npsList.length
                            ?
                            <ReportCard>
                                <ResearchesReport npsList={npsList} />
                            </ReportCard>
                            :
                            null
                    }
                </div>
            </div>
            {loading && <Spinner />}
        </>
    );
}

export default memo(ClientReport);