import axios from 'axios';

import { getToken } from './auth'

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api/v1/`
});

api.interceptors.request.use(config => {
    const token = getToken();
    // window.showLoading();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    window.showNotification('error', 'Houve um erro ao realizar a sua requisição!');
    return Promise.reject(error);
});

api.interceptors.response.use(response => {
    return response;
}, error => {
    window.showNotification('error', 'Houve um erro ao finalizar a sua requisição!');
    return Promise.reject(error);
});

export default api;