import React from 'react';
import Select from 'react-select'

function ClientEdition({ sendNps, services, activeServices, addService, clientName, clientEmail, clientActive, responsibleName, sendingDay, setClientName, setClientEmail, setResponsibleName, setSendingDay, selectActions }) {

    function showServiceList(element) {
        let listElement = document.getElementById(element.dataset.dropdown);
        if (listElement.classList.contains('show')) {
            listElement.classList.remove('show');
        } else {
            listElement.classList.add('show');
        }
    }



    window.onclick = function (event) {
        if (!event.target.matches('.button-dropdown')) {
            var dropdowns = document.getElementsByClassName("dropdown-menu");
            var i;
            for (i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show')) {
                    openDropdown.classList.remove('show');
                }
            }
        }
    }

    return (
        <form action="#" id="client-edition-form" autoComplete="false">
            <div className="row">
                <div className="col-md-4">
                    <div className="input-block d-flex flex-column">
                        <label htmlFor="client_name">Nome do cliente</label>
                        <input value={clientName} onChange={(e) => setClientName(e.target.value)} type="text" name="name" id="client_name" />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-block d-flex flex-column">
                        <label htmlFor="client_email">Email</label>
                        <input value={clientEmail} onChange={(e) => setClientEmail(e.target.value)} type="email" name="email" id="client_email" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="input-block d-flex flex-column">
                        <label htmlFor="responsible_name">Responsável</label>
                        <input value={responsibleName} onChange={(e) => setResponsibleName(e.target.value)} type="text" name="responsible_name" id="client_responsible_name" />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-block d-flex flex-column">
                        <label htmlFor="sending_day">Dia de disparo</label>
                        <input value={sendingDay} onChange={(e) => setSendingDay(e.target.value)} type="text" name="sending_day" id="client_sending_day" />
                    </div>
                </div>
            </div>
            <div className="divisor row my-4">
                <div className="col-md-12 d-flex align-items-center">
                    <span className="mr-3">Serviços associados</span>
                    <hr className="w-100 flex-1" />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 text-right">
                    <div className="btn-group dropleft">
                        {
                            clientActive
                            ?
                            <button type="button" className="custom-btn button-dropdown"
                                data-dropdown="service-dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                onClick={(e) => showServiceList(e.target)}>
                                Adicionar serviço
                            </button>
                            :
                            null
                        }
                        <div id="service-dropdown" className="dropdown-menu">
                            {
                                services.length
                                    ?
                                    services.map(service => (
                                        <div key={service.id} className="dropdown-item" onClick={() => addService(service.id, service.name)}>
                                            <span>{service.name}</span>
                                        </div>
                                    ))
                                    :
                                    <div className="dropdown-item">
                                        <span>Nenhum serviço encontrado</span>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-12">
                    {
                        activeServices.length
                            ?
                            activeServices.map((activeService, key) => (
                                <div key={key} className={`row ${key ? 'mt-5' : ''}`}>
                                    <div className="col-md-12">
                                        {
                                            clientActive
                                            ?
                                            <div className="row">
                                                <div className="col-md-12 d-flex align-items-center">
                                                    <span>{activeService.name}</span>
                                                    <button type="button" onClick={(e) => sendNps(activeService.id)} className="ml-3 fs-12 custom-btn btn-green d-flex align-items-center justify-content-center">
                                                        Enviar pesquisa
                                                    </button>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Select className="mt-3" isMulti={true} placeholder="Selecione as questões"
                                                    options={activeService.questions}
                                                    isDisabled={!clientActive}
                                                    noOptionsMessage={() => "Nenhuma opção encontrada"}
                                                    defaultValue={activeService.selected}
                                                    onChange={(data, action) => selectActions(data, action)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                            :
                            null
                    }
                </div>
            </div>
        </form>
    );
}

export default ClientEdition;