import React from 'react';

export default function DashboardItem({ children, title, value, icon }) {
    return (
        <div className="col-md-4">
            <div className="dashboard-item observations row align-items-center">
                <div className={`${children ? 'col-md-12' : 'col-md-9'}`}>
                    <div>
                        <span className="item-title text-uppercase">{title}</span>
                    </div>
                    <div>
                        {
                            children
                                ?
                                children
                                :
                                <span className="item-value">{value}</span>
                        }
                    </div>
                </div>
                {
                    icon
                        ?
                        <div className="col-md-3">
                            <div className="item-icon">
                                <i className="material-icons d-flex align-items-center justify-content-center">{icon}</i>
                            </div>
                        </div>
                        :
                        null
                }
            </div>
        </div>
    );
}