import React, { useState, createContext } from 'react';
import CircleProgress from '../../components/CircleProgress';

import CustomDataTable from '../../components/CustomDataTable';
import Spinner from '../../components/Spinner';

import api from '../../services/api';
import SendAnswerEmail from '../SendAnswerEmail';

function ResearchesReport({ npsList }) {
    const [activeService, setActiveService] = useState(0);
    const [researcheModal, setResearcheModal] = useState(false);
    const [researcheData, setResearcheData] = useState({});
    const [actionSidenav, setActionSidenav] = useState(0);
    const [loading, setLoading] = useState(false);
    const contextActionSidenav = createContext([actionSidenav, setActionSidenav]);

    const respondent = (row) => {
        if(row.sended_by_email || row.sended_by_email == null) {
            return (
                <span><b>Enviado para: </b>{row.respondent_name}<br/>({row.respondent_email})</span>
            );
        }
        return (
            <span><b>Link gerado</b></span>
        );
    }

    const dataTableColumns = [
        {
            selector: 'sended_date',
            sortable: true,
            center: true,
            cell: row => {
                let date = new Date(row.sended_date);
                const day = ("0" + (date.getDate())).slice(-2);
                const month = ("0" + (date.getMonth() + 1)).slice(-2);
                return (<span><b>Dia do envio: </b>{`${day}/${month}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`}</span>)
            }
        },
        {
            selector: 'respondent_name',
            sortable: true,
            center: true,
            cell: row => respondent(row)
            // cell: row => {if(row.sended_by_email || row.sended_by_email == null) {<span><b>Enviado para: </b>{row.respondent_name}<br/>({row.respondent_email})</span>} else {<span><b>Link gerado</b></span>}}
        },
        {
            selector: 'sended_answer_email',
            sortable: true,
            center: true,
            cell: row => <span><b>Resposta: </b>{row.sended_answer_email || row.score ? 'Sim' : 'Não'}</span>
        },
        {
            selector: 'opened',
            sortable: true,
            center: true,
            cell: row => <span><b>Visualizado: </b>{row.opened || row.score ? 'Sim' : 'Não'}</span>
        },
        {
            selector: 'score',
            sortable: true,
            center: true,
            cell: row => <span><b>Score: </b>{row.score ?? 'Não respondido'}</span>
        }
    ];

    async function rowClicked(rowData) {
        if (!rowData.finished_at) {
            window.showNotification('error', 'Pesquisa não respondida')
            return;
        }
        setLoading(true);
        await api.get(`answers/nps/${rowData.nps_id}`).then(response => {
            setLoading(false);
            window.bodyOverlay();
            rowData['answersList'] = response.data.data;
            setResearcheData(rowData);
            setResearcheModal(true);
        })
    }

    function closeResearcheModal() {
        setResearcheModal(false)
        window.bodyOverlay();
    }

    return (
        <>
            {
                researcheModal
                    ?
                    <>
                        <SendAnswerEmail npsId={researcheData.nps_id} action={contextActionSidenav} />
                        <div className="custom-modal">
                            <div className="container h-100">
                                <div className="row h-100 justify-content-center align-items-center">
                                    <div className="col-md-10">
                                        <div className="custom-modal-block">
                                            <div className="custom-modal-top d-flex justify-content-end">
                                                <button className="empty-btn d-flex align-items-center" type="button">
                                                    <span className="material-icons" onClick={() => closeResearcheModal()}>close</span>
                                                </button>
                                            </div>
                                            <div className="custom-modal-body">
                                                <div className="nps-info-block row">
                                                    <div className="col-md-5">
                                                        <CircleProgress score={researcheData.score} />
                                                        <div className="mt-20">
                                                            <p><b>Como podemos melhorar?</b></p>
                                                            <p className="mb-0 fs-15">{researcheData.observation ?? 'Nenhuma observação informada'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="offset-md-1 col-md-6">
                                                        {
                                                            researcheData.answersList
                                                                ?
                                                                researcheData['answersList'].map(answer => (
                                                                    <>
                                                                        {
                                                                            <div key={answer.answer_id} className="question-item d-flex justify-content-between align-items-center">
                                                                                <span>{answer.question_content}</span>
                                                                                <div className="likeDislike d-flex">
                                                                                    <div className="dislike-block">
                                                                                        <span className={`dislike ${!answer.answer_result ? 'active' : ''}`}>
                                                                                            <i className="material-icons">thumb_down</i>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="like-block">
                                                                                        <span className={`like ${answer.answer_result ? 'active' : ''}`}>
                                                                                            <i className="material-icons">thumb_up</i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                ))
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-md-12 d-flex justify-content-center">
                                                        {
                                                            !researcheData.sended_answer_email
                                                                ?
                                                                <button type="button" id="send-answer-email" className="custom-btn btn-green d-flex align-items-center" onClick={() => setActionSidenav(true)}>
                                                                    <i className="material-icons mr-2">email</i> Responder cliente
                                                                </button>
                                                                :
                                                                null

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    null
            }
            <div className="row">
                <div className="col-md-8 report-card-title">
                    <span className="fw-500">{npsList[activeService].service_name}</span>
                </div>
                <div className="col-md-4 report-card-title d-flex justify-content-end align-items-center">
                    <button className="navigation-button d-flex align-items-center prev-service" disabled={!activeService} onClick={() => setActiveService(activeService - 1)}>
                        <span className="material-icons">arrow_back_ios</span>
                    </button>
                    <button className="navigation-button d-flex align-items-center next-service" disabled={(npsList.length - 1) === activeService} onClick={() => setActiveService(activeService + 1)}>
                        <span className="material-icons">arrow_forward_ios</span>
                    </button>
                </div>
            </div>
            {
                npsList.map((item, index) => (
                    <div key={index} className={`row mt-3 ${activeService !== index ? 'd-none' : ''}`}>
                        <div className="col-md-12">
                            <CustomDataTable data={item['list']} columns={dataTableColumns} rowClicked={rowClicked} />
                        </div>
                    </div>
                ))
            }
            {loading && <Spinner />}
        </>
    );
}

export default ResearchesReport;