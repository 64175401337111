import React, { useState } from 'react';

function QuestionsReport({ questionsAnswersReport }) {

    const [activeService, setActiveService] = useState(0);

    return (
        <>
            <div className="row">
                <div className="col-md-8 report-card-title">
                    <span className="fw-500">{questionsAnswersReport[activeService].service_name}</span>
                </div>
                <div className="col-md-4 report-card-title d-flex justify-content-end align-items-center">
                    <button className="navigation-button d-flex align-items-center prev-service" disabled={!activeService} onClick={() => setActiveService(activeService - 1)}>
                        <span className="material-icons">arrow_back_ios</span>
                    </button>
                    <button className="navigation-button d-flex align-items-center next-service" disabled={(questionsAnswersReport.length - 1) === activeService} onClick={() => setActiveService(activeService + 1)}>
                        <span className="material-icons">arrow_forward_ios</span>
                    </button>
                </div>
            </div>
            {
                questionsAnswersReport.map((item, index) => (
                    <div key={index} className={`row mt-3 ${activeService !== index ? 'd-none' : ''}`}>
                        <div className="col-md-12">
                            <table className="answers-table table mt-3">
                                <thead>
                                    <tr>
                                        <th>Likes</th>
                                        <th>Dislikes</th>
                                        <th>Questão</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        item['questions'].map((question, index) => (
                                            <tr key={index}>
                                                <td><p className="mb-0">{question.likes_quantity}</p></td>
                                                <td><p className="mb-0">{question.dislikes_quantity}</p></td>
                                                <td><p className="mb-0">{question.question_content}</p></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                ))
            }
        </>
    );
}

export default QuestionsReport;