import React from 'react';

import './main.css'
function Applications() {
  return (
      <div id="applications-box">
          <div className="container">
              <div className="row">
                  <div className="col-md-12">
                      <div className="logo-container">
                          <img src={process.env.REACT_APP_COMPANY_ICON ? process.env.REACT_APP_COMPANY_ICON : "/cube_clean.png"} alt="Verticis" width="40px"/>
                      </div>
                  </div>
              </div>
              <div className="row applications-container">
                  <div className="col-md-12">
                      <div className="application-item">
                        <span className="material-icons">alarm</span>
                      </div>
                      <div className="application-item app-active">
                          <span className="material-icons">email</span>
                      </div>
                      <div className="application-item">
                          <span className="material-icons">work</span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
}

export default Applications;