import React from 'react';

function ClientLevel({ score }) {
    let clientLevel;
    if (score !== '-' && score !== null) {
        clientLevel = score >= 9 ? 'promotor' : (score >= 7) ? 'neutro' : 'detrator';
    }

    return <span className={`client-level ${clientLevel ?? 'empty'}-block`}>{clientLevel ?? 'Não avaliado'}</span>;
}

export default ClientLevel;