import React, { useState, useContext } from 'react';

import api from '../../services/api'

import Noty from 'noty';

import 'noty/lib/noty.css';
import 'noty/lib/themes/mint.css';
import Sidenav from '../../components/Sidenav';
import Spinner from '../../components/Spinner';
import { useHistory } from 'react-router-dom';


function ClientSimpleForm() {
    let history = useHistory();
    const [clientName, setClientName] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [clientResponsibleName, setClientResponsibleName] = useState('');
    // const [actionSidenav, setActionSidenav] = useContext(action);
    const [clientSendingDay, setClientSendingDay] = useState('');
    const [clientTest, setClientTest] = useState(false);
    const [loading, setLoading] = useState(false);

    async function handleSubmit(e) {
        setLoading(true);
        e.preventDefault();
        await api.post(`client/store`, {
            name: clientName,
            email: clientEmail,
            responsible_name: clientResponsibleName,
            sending_day: clientSendingDay,
            test_client: clientTest
        }).then(response => {
            setLoading(false);
            new Noty({
                theme: 'mint',
                timeout: 10000,
                progressBar: true,
                closeWith: ['click', 'button'],
                type: 'success',
                layout: 'topRight',
                text: 'Cliente cadastrado com sucesso!'
            }).show();
            history.replace({
                pathname: `/clientes/${response.data.client.id}`,
                state: { clientName: response.data.client.name, average: '-' }
            })
        })
    }

    return (
        <>
            <form onSubmit={handleSubmit} className="text-left">
                <div className="row">
                    <div className="col-md-6">
                        <div className="input-block d-flex flex-column">
                            <label htmlFor="client_name">Nome</label>
                            <input type="text"
                                name="name"
                                id="client_name"
                                placeholder="Nome"
                                value={clientName}
                                onChange={(e) => setClientName(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-block d-flex flex-column">
                            <label htmlFor="client_email">E-mail</label>
                            <input type="text"
                                name="email"
                                id="client_email"
                                placeholder="E-mail"
                                required
                                value={clientEmail}
                                onChange={(e) => setClientEmail(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="input-block d-flex flex-column">
                            <label htmlFor="client_resposible_name">Nome do responsável</label>
                            <input type="text"
                                name="responsible_name"
                                id="client_responsible_name"
                                required
                                placeholder="Nome do responsável"
                                value={clientResponsibleName}
                                onChange={(e) => setClientResponsibleName(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-block d-flex flex-column">
                            <label htmlFor="client_sending_day">Dia de disparo</label>
                            <input type="number"
                                max="28"
                                min="1"
                                name="sending_day"
                                id="client_sending_day"
                                required
                                placeholder="Dia de disparo"
                                value={clientSendingDay}
                                onChange={(e) => setClientSendingDay(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="input-block d-flex flex-column">
                        <label htmlFor="test_client">Usuário para testes</label>
                        <input type="checkbox" name="test_client" id="test_client" checked={clientTest} onChange={() => setClientTest(!clientTest)} />
                    </div>
                </div>

                <div className="submit-form">
                    <button type="submit">Finalizar</button>
                </div>
            </form>
            {loading && <Spinner />}
        </>
    );
}

export default ClientSimpleForm;
