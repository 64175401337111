import React from 'react';

function EnterpriseLevel({ score }) {
    let enterpriseLevel;
    if (score !== '-' && score !== null && score !== 0) {
        enterpriseLevel = score >= 75 ? 'sentiment_very_satisfied' : (score >= 50 && score <= 74) ? 'sentiment_satisfied' : (score >= 0 && score <= 49) ? 'sentiment_dissatisfied' : 'sentiment_very_dissatisfied';
    }

    return <div className="row">
        <div className="col-md-6">
            <h3>{Math.round(score)}</h3>
        </div>
        <div className="col-md-1">
            <div className="item-icon"> 
                <i className="material-icons d-flex align-items-center justify-content-center pt-1">{enterpriseLevel}</i>
            </div>
        </div>
    </div>
}

export default EnterpriseLevel;