import React, { useState, useEffect, createContext } from 'react';
import { useHistory } from "react-router-dom";

import CustomDataTable from '../../components/CustomDataTable';
import FloatButton from '../../components/FloatButton';
import PageTop from '../../components/PageTop';
import ServiceSimpleForm from '../../parts/ServiceSimpleForm';

import Spinner from '../../components/Spinner';

import api from '../../services/api';

function Service() {
    let history = useHistory();
    const [actionSidenav, setActionSidenav] = useState(0);
    const contextActionSidenav = createContext([actionSidenav, setActionSidenav]);
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function loadServices() {
            setLoading(true);
            await api.get('service/all')
                .then(response => {
                    setServices([...response.data.data]);
                    setLoading(false);
                })
        }

        loadServices();
    }, [])

    async function deleteService(serviceId) {
        setLoading(true);
        await api.delete(`service/${serviceId}`)
            .then(response => {
                setLoading(false);
                window.showNotification('success', 'Serviço excluído com sucesso!')
                setServices(services.filter(service => service.id !== serviceId));
            }).catch(response => {
                setLoading(false);
                window.showNotification('error', 'Houve um erro ao excluir o serviço!')
            })
    }

    const dataTableColumns = [
        {
            selector: 'name',
            sortable: true,
            center: true
        },
        {
            selector: 'trash',
            sortable: false,
            right: true,
            cell: row => <button type="button" onClick={() => deleteService(row.id)} className="btn btn-danger d-flex"><i className="material-icons fs-15" >delete</i></button>
        }
    ];

    function rowClicked(rowData) {
        history.replace({
            pathname: `/servico/${rowData.id}`,
            state: { serviceName: rowData.name }
        })
    }

    function sidenavAction() {
        setActionSidenav(!actionSidenav);
    }

    return (
        <>
            <PageTop title={'Serviços'}>
                <FloatButton location={'top'} action={sidenavAction} />
            </PageTop>

            <CustomDataTable data={services} columns={dataTableColumns} rowClicked={rowClicked} />
            <ServiceSimpleForm action={contextActionSidenav} />
            {loading && <Spinner />}
        </>
    );
}

export default Service;