import React from 'react';

import { NavLink } from 'react-router-dom';

import './main.css'

function Menu() {
  return (
        <nav id="menu-nav">
            <div className="menu-container">
                <div className="menu-item">
                    <NavLink exact to="/" activeClassName="menu-item-active">
                        <span className="material-icons">dashboard</span>
                        Dashboard
                    </NavLink>
                </div>
                <div className="menu-item">
                    <NavLink to="/clientes" activeClassName="menu-item-active">
                        <span className="material-icons">person</span>
                        Clientes
                    </NavLink>
                </div>
                <div className="menu-item">
                    <NavLink to="/servicos" activeClassName="menu-item-active">
                        <span className="material-icons">work</span>
                        Serviços
                    </NavLink>
                </div>
                <div className="menu-item">
                    <NavLink to="/perfil" activeClassName="menu-item-active">
                        <span className="material-icons">account_circle</span>
                        Perfil
                    </NavLink>
                </div>
            </div>
        </nav>
  );
}

export default Menu;