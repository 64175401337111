import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../services/api'
import { getToken } from '../../services/auth';

import Spinner from '../../components/Spinner';

import Noty from 'noty';

import 'noty/lib/noty.css';
import 'noty/lib/themes/mint.css';

function ClientSimpleForm() {
    const [file, setFile] = useState(null);
    const [selectedServices, setSelectedServices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState([]);

    useEffect(() => {
        async function loadServices() {
            setLoading(true);
            await api.get('service/all')
                .then(response => {
                    setServices([...response.data.data]);
                    setLoading(false);
                })
        }

        loadServices();
    }, []);

    function handleCheck(e) {
        if(e.target.checked) {
            setSelectedServices(selectedServices => [...selectedServices, e.target.value]);
        } else {
            const index = selectedServices.indexOf(e.target.value);
            selectedServices.splice(index, 1);
        }
    }

    async function handleSubmit(e) {
        setLoading(true);
        e.preventDefault();
        const user = getToken();
        var formData = new FormData();

		formData.append('fileToImport', file);
        selectedServices.map((service) => {
            formData.append('services[]', service);
        });

        await api.post(`client/import-from-list`, formData, {
            headers: {
                Authorization: 'Bearer ' + user,
                'Access-Control-Allow-Origin': '*',
                'content-type': 'multipart/form-data'
            }
        }).then(response => {
            setLoading(false);
            window.showNotification('success', response.data.success);
            if(response.data.failures) {
                response.data.failures.forEach(failure => {
                    window.showNotification('warning', 'A linha ' + failure.row + ' não foi importada porque ' + failure.errors[0]);
                });
            }
        });
    }

    return (
        <>
            <form onSubmit={handleSubmit} className="text-left">
                <div className="row pt-4">
                    <div className="col-md-12">
                        <div className="input-block d-flex flex-column">
                            <h5 className="mb-3" >Arquivo para importação</h5>
                            <input 
                                type="file" 
                                onChange={(e)=> setFile(e.target.files[0])}
                                name="file"
                                required
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
                            />
                            <h5 className="mt-4 fs-2 mb-3">Serviço</h5>
                            <div className="d-flex flex-wrap">
                                {services.map((service, index) => (
                                    <div className="form-check mr-3" key={index}>
                                        <input 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            value={service.id} 
                                            id={service.id} 
                                            onChange={(e) => handleCheck(e)}
                                        />
                                        <label className="form-check-label" htmlFor={service.id}>
                                            {service.name}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="submit-form mt-5">
                            <button type="submit" className="btn btn-dark">Importar</button>
                        </div>
                    </div>
                </div>
            </form>
            {loading && <Spinner />}
        </>
    );
}

export default ClientSimpleForm;
